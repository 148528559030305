var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attendance-ada"},[_c('div',[_c('div',{staticClass:"attendance-dropdowns"},[_vm._m(0),_c('div',{staticClass:"dropdown-options"},[_c('div',{staticClass:"site-dropdown"},[_c('dropdown',{attrs:{"classes":['filter-select-dropdown program-select-dropdown'],"dropdown":Object.assign({}, _vm.singleSiteDropdown,
              {dropdownList: _vm.sitesDropdown,
              value: _vm.selectedSite.value
                ? _vm.selectedSite.value
                : 'Select Session',
              id: _vm.selectedSite.id,
              disable: _vm.isLineChartLoaderVisible ? true:_vm.sitesDropdown && _vm.sitesDropdown.length>1 ? false : true,
              singleSelect: _vm.sitesDropdown && _vm.sitesDropdown.length==1}),"bx-attr":"singleprogramDropdown"},on:{"onSelectionChange":_vm.singleSiteSelection}})],1),_c('div',{staticClass:"groups-dropdown",class:[_vm.isGroupsDisabled ? 'dropdown-no-focus' : '']},[_c('multi-select-dropdown',{attrs:{"dropdown":Object.assign({}, _vm.multiSiteDropdown,
              {dropdownList: _vm.selectedSiteGroups,
              disable: _vm.isLineChartLoaderVisible || _vm.isGroupsDisabled}),"bx-attr":"programDropdown"},on:{"selectionRecords":function($event){return _vm.selectGroup($event)},"multiSelectDropdownClosed":function($event){return _vm.prepareGraphData()}}})],1),_c('div',{staticClass:"toggle-tab-container"},[_c('div',{staticClass:"master-tab"},_vm._l((_vm.masterTabsValue),function(tab,tabIndex){return _c('div',{key:tabIndex,class:[
                _vm.selectedTab == tab.name
                  ? 'tab-button-selected'
                  : 'list-default-selection',
                tab.name ],attrs:{"bx-attr":tab.name},on:{"click":function($event){return _vm.tabSwitch(tab)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0)])])]),_c('div',[(_vm.isLineChartLoaderVisible)?_c('div',{staticClass:"loader-style"},[_c('bouncing-preloader')],1):_c('div',{staticClass:"line-chart-section"},[_c('div',{staticClass:"flex-full-width-ada"},[_c('div',{staticClass:"main-container-ada-submissions"},[_c('div',{staticClass:"line-chart-data"},[_c('div',{staticClass:"lineChartOuter",attrs:{"id":"line-chart-ada-week"}},[_c('line-chart',{staticClass:"walking-speed-chart",style:(_vm.lineChartStyle),attrs:{"isMobileWidth":_vm.isMobileView,"data":_vm.adaWeekLineData,"printChart":false,"yScaleLabel":_vm.yScaleLabel,"isLabelOffSet":_vm.selectedTab,"hideAnnotation":false,"isTooltipInPercent":true}})],1),_c('canvas',{attrs:{"id":"myChartAxis","height":"600","width":"0"}}),(_vm.showLegends)?_c('div',{staticClass:"legends-ada"},_vm._l((_vm.adaWeekLineData.datasets),function(data){return _c('div',{key:data.label,staticClass:"container-ada"},[_c('span',{staticClass:"dot-ada",style:({ backgroundColor: data.borderColor })}),_c('span',{staticClass:"Ada-attendance",style:({ color: data.borderColor })},[_vm._v(_vm._s(data.label))])])}),0):_vm._e(),_c('div',{staticClass:"goal",class:[
                  _vm.showLegends
                    ? _vm.responseReceived
                      ? 'goal-with-groups'
                      : 'no-respone'
                    : _vm.responseReceived
                    ? 'goal-without-groups'
                    : 'no-respone-group-site' ]},[_vm._v(" "+_vm._s("80% Goal")+" ")]),(_vm.emtyStateMessage && _vm.emtyStateMessage != '')?_c('div',{staticClass:"emptyState"},[_c('p',{attrs:{"bx-attr":"no-courses"}},[_vm._v(_vm._s(_vm.emtyStateMessage))])]):_vm._e()])])])])])]),(_vm.highestRole <= _vm.localConst.ROLE_TYPE_PROGRAM_ADMIN)?_c('div',{staticClass:"bar-chart"},[_c('div',{staticClass:"attendance-dropdowns"},[_c('div',{staticClass:"chart-Heading"},[_c('span',{staticClass:"program-lable"},[_vm._v("Program ADA"),_c('span',{class:[_vm.adaProgramPercentage=='--' ?'program-percent-empty-case':'program-percent']},[_vm._v(_vm._s(_vm.adaProgramPercentage))])])])]),(_vm.isBarChartLoaderVisible)?_c('div',{staticClass:"loader-style"},[_c('bouncing-preloader')],1):_c('div',{staticClass:"chart-section"},[_c('div',{staticClass:"chart-parent"},[_c('BarChart',{style:(_vm.barChartStyle),attrs:{"barEmtyStateMessage":_vm.barEmtyStateMessage,"isMobileWidth":_vm.isMobileView,"data":_vm.adaBarGraphData,"hideBarAnnotation":false,"xAxisLabel":_vm.xAxisLabelString,"yAxisLabel":_vm.yScaleLabelBar,"isDisplayXLabel":_vm.isDisplayXLabel,"barChartBackground":_vm.barChartBackground,"barChartHoverBackground":_vm.barChartHoverBackground}})],1),_c('canvas',{attrs:{"id":"BarYAxis","height":"0","width":"0"}}),(_vm.adaResponseReceived)?_c('div',{staticClass:"ada-goal",style:(_vm.adaBarGraphData.length == 0 ? 'top:19%' : '')},[_vm._v(" "+_vm._s('80% Goal')+" ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-Heading"},[_c('span',[_vm._v("ADA by Session")])])}]

export { render, staticRenderFns }